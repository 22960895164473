//
// Layout composition
//
// @maintainer: Konstantin Dzuin (k.dzuin@corp.badoo.com)
// @maintainer: Ivan Grekov (ivan.grekov@corp.badoo.com)
//

.layout {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background: #fff;
}

.layout__inner {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
    background: #fff;

    .show-cookie-notification & {
        padding-bottom: 92px;

        @media (min-width: 770px) {
            padding-bottom: 56px;
        }
    }

    @media print {
        display: block;
    }
}

.layout__header {
    flex: 0 0 auto;

    @media print {
        display: none;
    }
}

.layout__header--full-height {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    @media (min-width: 770px) {
        display: block;
    }
}

.layout__content {
    position: relative;
    flex: 0 0 auto;
    max-width: $layout-max-width;
    width: 100%;
    margin: 0 auto;
    padding: 0 32px;

    @media (min-width: 770px) {
        padding: 0 32px;
    }
    @media (min-width: 1376px) {
        padding: 0;
    }
}

.layout__content--wide {
    max-width: none;
}

.layout__content--centered {
    text-align: center;
}

.layout__content--balanced {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 32px;
    padding-bottom: 32px;
}

.layout__slider {
    max-width: 1376px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: 1200px) {
        padding: 0 32px;
    }
    @media (min-width: 1376px) {
        padding: 0;
    }
}

.layout__instagram-feed {
    margin: 0 -16px;

    @media screen and (min-width: 1200px) {
        margin: 0;
    }
}

.layout__footer {
    flex: 0 0 auto;
    max-width: 720px;
    width: 100%;
    margin: 0 auto 32px;
    padding: 32px 16px 0;

    @media (min-width: 770px) {
        padding: 0;
    }
    @media (min-width: 1200px) {
        display: block;
    }
    @media print {
        display: none;
    }
}

// Extra sections (optional)
//

.layout__sections {
    display: block;

    @media (min-width: 770px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    @media print {
        display: block;
    }
}

.layout__main {
    display: block;

    @media (min-width: 770px) {
        display: block;
        flex: 1 1 auto;
        min-width: 0;
    }
}

.layout__sidebar {
    display: block;

    @media (min-width: 770px) {
        display: block;
        flex: 0 0 auto;
        width: 310px;
        padding-top: 5px;
    }
    @media print {
        display: block;
        width: 100%;
    }
}

.layout__sidebar--sticky {
    @media (min-width: 770px) {
        @supports (position: sticky) {
            position: sticky;
            top: ($layout-header-height + 30px);
        }
    }
}

.layout__sidebar + .layout__main,
.layout__main + .layout__sidebar {
    margin-top: 35px;

    @media (min-width: 770px) {
        margin-top: 0;
        margin-left: 95px;
    }
    @media print {
        margin-top: 30px;
        margin-left: 0;
    }
}

.layout__press-kit {
    @media (min-width: 770px) {
        margin-bottom: 32px;
    }
}

.layout__terms {
    display: block;
    max-width: 680px;
    margin: 0 auto 32px;
}


// interactions 
//

.layout__header + .layout__content,
.layout__header + .layout__footer {
    margin-top: 32px;

    @media (min-width: 770px) {
        margin-top: 48px;
    }
}

.layout__vacancies {
    max-width: 654px;
    margin: 0 auto;
}

.layout__features + .layout__promo {
    margin-top: 48px;

    @media (min-width: 770px) {
        margin-top: 64px;
    }
}

.layout__content + .layout__slider {
    margin-top: 32px;

    @media (min-width: 770px) {
        margin-top: 64px;
    }
}

.layout__slider + .layout__content {
    margin-top: 32px;

    @media (min-width: 1200px) {
        margin-top: 64px;
    }
}

.layout__content + .layout__footer {
    margin-top: 16px;

    @media screen and (min-width: 770px) {
        margin-top: 32px;
    }

    @media screen and (min-width: 770px) {
        margin-top: 64px;
    }
}

.layout__content--beehive {
    margin-top: 64px;
    margin-bottom: 32px;
}

.layout__content--balanced {
    .layout__header + &,
    .layout__slider + &,
    .layout__content + & {
        margin-top: auto;
    }

    & + &,
    & + .layout__footer,
    & + .layout__slider,
    & + .layout__content {
        margin-top: 0;
    }

    & + & {
        padding-top: 0;
    }
}

.layout__instagram-feed + .layout__cta {
    margin-top: 64px;
}

.layout__promo + .layout__press-kit {
    margin-top: 64px;
}

// States
.layout__cta {
    &.is-hidden {
        display: none;
    }
}

// TODO: create modifieres for layout__contet to be able to center things inside
.layout__captcha {
    display: flex;
    justify-content: center;
    align-items: center;
}
