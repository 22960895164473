//
// Header dropdown
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

@import '../__mixins/a11y-focus-ring.scss';

// Elements
//
.header-dropdown__item {
    min-width: 70px;
    color: $color-base;
    transition: .2s color linear;

    &:first-child {
        padding-top: 8px;
    }

    &:last-child {
        padding-bottom: 16px;
    }

    &:hover {
        color: $color-mode-dating;
    }
}

.header-dropdown__item--bumble {
    color: $color-mode-dating;

    &:hover {
        color: $color-base;
    }
}

.header-dropdown__item--bff {
    color: $color-mode-bff;

    &:hover {
        color: $color-base;
    }
}

.header-dropdown__item--bizz {
    color: $color-mode-bizz;

    &:hover {
        color: $color-base;
    }
}

.header-dropdown__link {
    @include a11y-focus-ring('inside');

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    color: inherit;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.33;
    transition: color .15s;
}

.header-dropdown__link-text {
    display: block;
    padding: 8px;
    font-weight: 600;
}
