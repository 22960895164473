//
// Form field
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

.form-field {
    display: block;
}

.form-field__label {
    display: block;
    opacity: .5;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    font-family: $font-family-custom;
    transition: opacity .2s;

    .form-field.is-focused & {
        opacity: 1;
    }
}

.form-field__error {
    display: none;
    color: $color-input-text-error;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    font-family: $font-family-custom;
}

.form-field__input {
    display: block;

    .form-field--stretch & {
        display: flex;
        align-items: stretch;
        flex: 1 1 auto;
    }

    .is-invalid & input {
        border: 1px solid #ff0000;
    }
}

.form-field__radio {
    & + & {
        margin-top: 8px;
    }
}

.form-field__label {
    .is-invalid & {
        display: none;
    }
}

.form-field__error {
    .is-invalid & {
        display: block;
    }
}

.form-field__date {
    display: flex;
    justify-content: space-between;
    max-width: 260px;
}

.form-field__card {
    display: flex;
}

// Modificators
//
.form-field--captcha,
.form-field--phone {
    width: 300px;
}

.form-field__error--static {
    display: block;
}

.form-field__input--radio-set {
    display: flex;
    flex-direction: column;
    padding-top: 8px;

    @media (min-width: 770px) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.form-field--stretch {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 auto;
}

.form-field---short {
    max-width: 260px;
}

// Relationships
//
.form-field + .form-field {
    margin-top: 16px;

    .form__field--inline & {
        margin-top: 0;
        margin-left: 8px;
    }
}

.form-field__input + .form-field__error,
.form-field__error + .form-field__input,
.form-field__label + .form-field__input {
    margin-top: 8px;
}

.form-field__input + .form-field__input {
    margin-top: 8px;
}

.form-field__date-item + .form-field__date-item {
    margin-left: 8px;
}

.form-field__card-item + .form-field__card-item {
    margin-left: 8px;
}
