.appstore-links {
    display: flex;
}


.appstore-links__item {
    display: flex;
    margin-top: -10px;

    img {
        object-position: left;
        object-fit: contain;
        pointer-events: none;
    }
}

.appstore-links__item--appstore {
    margin-left: -10px;

    img {
        margin: 10px;
    }
}

.appstore-links__item--googleplay {
    transform: scale(0.87);
}

.appstore-links__item--googleplay-en,
.appstore-links__item--googleplay-en-au,
.appstore-links__item--googleplay-en-ca,
.appstore-links__item--googleplay-en-in,
.appstore-links__item--googleplay-en-us {
    transform: scale(1);
}