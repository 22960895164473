//
// Footer
//
// @maintainer: Konstantin Dzuin (k.dzuin@corp.badoo.com)
// @maintainer: Ivan Grekov (ivan.grekov@corp.badoo.com)
//

.footer {
    display: block;
}

.footer__inner {
    width: 100%;
}

.footer__sections {
    display: block;
}

.footer__section {
    display: block;
    min-width: 160px;
    text-align: center;
}

.footer__section--main {
    flex: 1 1 auto;
}

.footer__section--social {
    display: block;

    @media (min-width: 770px) {
        display: flex;
        justify-content: center;
    }
}

.footer__logo {
    display: flex;
    align-self: center;
    justify-content: center;
    color: $color-mode-dating;

    @media (min-width: 770px) {
        justify-content: flex-start;
    }
}

.footer__caption {
    color: $color-gray-medium;
    display: block;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    line-height: 1;
}

.footer__caption-copyright {
    @media screen and (min-width: 480px) {
        display: inline-block;
    }
}

.footer__accounts {
    display: flex;
    justify-content: center;
}

// Relationships in the Footer
//

.footer__logo + .footer__accounts {
    margin-top: 32px;

    @media (min-width: 770px) {
        margin-top: 0;
        margin-left: 64px;
    }
}

.footer__section + .footer__section {
    margin-top: 24px;

    @media (min-width: 770px) {
        margin-top: 0;
        margin-left: 15px;
    }
}

.footer__section + .footer__section--main {
    margin-top: 32px;
    margin-left: 0;
}

.footer__sections + .footer__caption {
    margin-top: 12px;
}
