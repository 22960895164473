.language-selector {
    position: relative;
    display: block;

    .dropdown-list__item-title {
        font-size: 12px;
    }
}

.language-selector__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;

    &::after {
        display: block;
        margin-bottom: -1px;
        margin-left: 4px;
        content: '';
        border: solid transparent;
        border-width: 6px 6px 0 6px;
        border-top-color: currentColor;
    }
}

.language-selector__trigger {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: transparent;
    appearance: none;
}
