.social-sharing-block {
    position: relative;
    background-color: #ffe9ad;
    border-radius: 8px;
    border: 2px solid #444647;
    padding: 12px 10px 16px;

    .has-shadow & {
        padding: 15px 11px 20px;
        @include apply-screen-size(desktop-md) {
            padding-left: 25px;
        }
        @include apply-screen-size(desktop-lg) {
            padding-left: 40px;
        }
    }
}

.social-sharing-block__title .p-1 {
    --font-size: 16.62px;
    font-weight: var(--font-weight-black, 900);
}

.social-sharing-block__title + .social-sharing-block__list {
    margin-top: 8px;
}