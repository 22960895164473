$slider-svg-filter-color: #444647;
@keyframes slideAnimation {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    11.11% {
        opacity: 1;
        transform: scale(0.995);
    }
    33.33% {
        opacity: 1;
        transform: scale(0.995);
    }
    44.44% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 0;
        transform: scale(0.9);
    }
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.slider-svg {
    position: relative;
    height: 100%;

    @include apply-screen-size(tablet-md) {
        max-height: 80vh;
    }

    @include apply-screen-size(desktop-md) {
        max-height: 1000px;
    }

    @media screen and (min-width: 1280px) and (min-height: 600px) {
        height: calc(100vh - 70px);
    }

    @media screen and (min-width: 1280px) and (min-height: 600px) {
        height: calc(100vh - 170px);
    }
}

.slider-svg__inner-wrapper {
    height: 100%;
    filter: drop-shadow(0.5px 0px 0px $slider-svg-filter-color)
        drop-shadow(-0.5px 0px 0px $slider-svg-filter-color)
        drop-shadow(0px 0.5px 0px $slider-svg-filter-color)
        drop-shadow(0px -0.5px 0px $slider-svg-filter-color)
        drop-shadow(0.5px 0.5px 0px $slider-svg-filter-color)
        drop-shadow(-0.5px -0.5px 0px $slider-svg-filter-color)
        drop-shadow(-0.5px 0.5px 0px $slider-svg-filter-color)
        drop-shadow(0.5px -0.5px 0px $slider-svg-filter-color);
}

.slider-svg__inner {
    display: block;
    width: 100%;
    height: 100%;
}

.slider-svg__slide {
    width: 100%;
    height: 100%;
    clip-path: url(#clip-path);
    opacity: 0;
    animation: slideAnimation 12s infinite cubic-bezier(0.5, -0.2, 0.5, 1.2);
    transform-origin: center;
    transition: transform cubic-bezier(0.5, -0.2, 0.5, 1.2);
}

.slider-svg__slide:nth-child(1) {
    animation-delay: 0s;
}
.slider-svg__slide:nth-child(2) {
    animation-delay: 3s;
}
.slider-svg__slide:nth-child(3) {
    animation-delay: 6s;
}
.slider-svg__slide:nth-child(4) {
    animation-delay: 9s;
}

.slider-svg__sticker {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40vw;
    max-width: 250px;

    @include apply-screen-size(tablet-md) {
        width: 20vw;
        max-width: 250px;
    }
}

.slider-svg__sticker-back {
    position: absolute;
    transform: scale(1.155);
    transform-origin: center;
}

.slider-svg__sticker--inner-text {
    transform: translate(13%, 50%);

    @include apply-screen-size(tablet-md) {
        width: 17vw;
        max-width: 220px;
        transform: translate(7%, 21%);
    }
}

.slider-svg__sticker--friends-interests {
    top: 0;
    left: 0;
    bottom: auto;
    transform: translate(-50%, 150%);
    aspect-ratio: 288/210;

    @include apply-screen-size(tablet-md) {
        transform: rotate(-6deg) translate(-235%, -80%);
        z-index: 1;
    }
    @include apply-screen-size(desktop-lg) {
        transform: rotate(-6deg) translate(-305%, -120%);
    }
}

.slider-svg__sticker--plant-parent {
    top: 0;
    right: 0;
    bottom: auto;
    transform: rotate(8.4deg) translate(-9%, -45%);

    @include apply-screen-size(tablet-md) {
        transform: rotate(8.4deg) translate(-163%, 520%)
    }

    @include apply-screen-size(desktop-lg) {
        transform: rotate(8.4deg) translate(-173%,600%)
    }

    .hero-image {
        min-height: 73px;

        &::before {
            padding-bottom: 0;
        }
    }
}
