//
// Colours
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
// @maintainer: Ivan Grekov (ivan.grekov@corp.badoo.com)
//

// SOME COSMOS TOKENS // Version 9.6.1
$token-color-black: #444647;
$token-color-gray-10: #f6f6f6;
$token-color-gray-30: #ededed;
$token-color-gray-50: #dcdcdc;
$token-color-gray-70: #949494;
$token-color-gray-90: #6b6b6b;
$token-color-primary: #ffc629;
$token-color-primary-dark: #de9e24;
$token-color-primary-light: #ffedb3;
$token-color-primary-lighter: #fff7de;
$token-color-feature-bff: #009abf;
$token-color-feature-bizz: #ff6c37;

// Colour Palette
$color-red: #ff5556;

//
$color-mode-dating: $token-color-primary;
$color-mode-bff: $token-color-feature-bff;
$color-mode-bizz: $token-color-feature-bizz;
$color-mode-bff-maya: #ffc629;

// Shades of gray
$color-gray-dark: $token-color-black;
$color-gray-medium: $token-color-gray-90;
$color-gray: $token-color-gray-50;
$color-gray-light: $token-color-gray-30;
$color-white: #fff;

// header

$color-header-menu-border: $color-mode-dating;

// Content
$color-base: $color-gray-dark;
$color-base-bff-maya: #444647;
$color-base-inverted: #fff;
$color-header: $color-gray-dark;
$color-active: $color-mode-dating;
$color-accent: $token-color-primary-dark;

// links
$color-link: $color-mode-dating;

// Buttons
$color-button-primary-bg: $color-mode-dating;
$color-button-primary-bg-active: $color-gray-dark;
$color-button-primary-text: #444647;
$color-button-primary-text-active: $color-white;

$color-button-blue-bg: $color-mode-bff;
$color-button-blue-bg-active: darken($color-mode-bff, 3%);
$color-button-blue-text: $color-white;
$color-button-blue-text-active: $color-white;

$color-button-pink-bg: $color-mode-bizz;
$color-button-pink-bg-active: darken($color-mode-bizz, 3%);
$color-button-pink-text: $color-white;
$color-button-pink-text-active: $color-white;

$color-button-white-bg: #fff;
$color-button-white-bg-active: darken(#fff, 3%);
$color-button-white-text: $color-gray-dark;

$color-button-black-bg: $color-gray-dark;
$color-button-black-bg-active: #444647;
$color-button-black-text: #fff;

// Inputs
$color-input-border: $color-gray-light;
$color-input-border-error: $color-red;
$color-input-shadow-error: $color-red;
$color-input-text: $color-base;
$color-input-text-error: $color-red;

// Faq
$color-faq-item-header: $color-header;
$color-faq-item-header-active: $color-mode-dating;

// Card
$color-card-bg: $color-mode-dating;
$color-card-content-bg: #fff;

// Slider
$color-slider-arrow-bg: $color-mode-dating;
$color-slider-arrow-bg-hover: $color-gray-dark;
$color-slider-arrow: $color-gray-dark;
$color-slider-arrow-hover: #fff;
$color-pagination-item: #444647;
$color-pagination-item-hover: $color-gray-dark;
$color-pagination-item-active: $color-mode-dating;

// socials
$color-social-link: $color-gray-dark;
$color-social-link-hover: #444647;
