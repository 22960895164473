@import './layout/layout.reference.scss';

:root {
    --color-base: #444647; // rgb(69, 70, 80);
    --color-base-deep: ##444647; // rgb(40, 40, 40);
    --color-grey: #757575;
    --color-grey-light: #f1f1f1;
    --color-error: #c84a39;
    --page-max-width: 1414px; // TODO: used only for footer. Maybe unify with other widths / gaps?
    --horizontal-gap: 20px;
    --horizontal-gap-lg: 32px;
    --vertical-gap: 16px;
    --horizontal-gap-large: calc(var(--horizontal-gap) * 2);
    --vertical-gap-large: calc(var(--horizontal-gap) * 2);
    --font-family-book: 'Circular20WebCyr-Book', -apple-system, 'San Francisco', 'Helvetica Neue', Roboto, 'Segoe WP', 'Segoe UI', sans-serif;
    --font-family-bold: 'Circular20WebCyr-Bold', -apple-system, 'San Francisco', 'Helvetica Neue', Roboto, 'Segoe WP', 'Segoe UI', sans-serif;
    --font-family-black: 'Circular20WebCyr-Black', -apple-system, 'San Francisco', 'Helvetica Neue', Roboto, 'Segoe WP', 'Segoe UI', sans-serif;
    --font-family-extra-black: 'Circular20WebCyr-Black', -apple-system, 'San Francisco', 'Helvetica Neue', Roboto, 'Segoe WP', 'Segoe UI', sans-serif;
    --font-weight-regular: normal;
    --font-weight-book: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;
    --font-weight-black: 900;
    --header1-font-size: 34px;
    --header1-line-height: 109%;
    --header2-font-size: 30px;
    --header2-line-height: 109%;
    --header3-font-size: 30px;
    --header3-line-height: 109%;
    --header4-font-size: 22px;
    --header4-line-height: 109%;
    --caption-font-size: 14px;
    --caption-line-height: 100%;
    --p1-font-size: 14px;
    --p1-line-height: 18px;
    --button-font-size: 14px;
    --button-line-height: 18px;
    --font-size-base: 16px;
    --line-height-base: 1.5;
    --button-white-border: ##444647;
    --header-height: 80px;
    --menu-background: #444647;
    --menu-foreground: #fff;
    --mobile-panel-z-index: 100;
    --page-progress-z-index: 90;
    --page-social-links-z-index: 80;
    --header-z-index: 85;
    --page-article-gap: 32px;
    --base-control-height: 44px;
    --control-height-lg: 56px;
    --input-height-base: var(--base-control-height);
    --input-height-lg: var(--control-height-lg);
    --button-size-base: var(--base-control-height);
    --button-cta-border-color: #ffc629;
    --color-primary: #ffc629;
    --theme-color-dating: #ffc629;
    --theme-color-bff: #009abf;
    --theme-color-bizz: #ff6c37;
    // new variables
    --header-bg-color: transparent;
    --header-button-color: #ffc629;
    --menu-button-color: #ffc629;
    --logo-color: #ffc629;
    --logo-width: 160px;
    --content-max-inner-width: 1096px;
    --content-section-padding-horizontal: 20px;
    --content-section-padding-vertical: 48px;
    --content-max-width: calc(var(--content-max-inner-width) + 2 * var(--content-section-padding-horizontal));

    // TODO: review the breakpoint or add a couple more
    @include apply-screen-size(desktop-sm) {
        --content-section-padding-horizontal: 28px;
        --content-section-padding-vertical: 88px;
    }
    @include apply-screen-size(desktop-md) {
        --content-section-padding-horizontal: 28px;
    }
    @include apply-screen-size(desktop-lg) {
        --content-max-inner-width: 1280px;
    }
}
