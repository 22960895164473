//
// Form
//
// @maintainer: Ivan Grekov (ivan.grekov@corp.badoo.com)
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

.form-wrapper {
    position: relative;
    display: block;
    max-width: 100%;
}

.form {
    display: block;
}

.form__success_notice {
    --hero-block-title-max-width: 654px;
    --hero-block-title-vertical-padding: 16px;
    --hero-block-title-horizontal-padding: 24px;
    --hero-block-border-radius: 8px;
    --hero-block-border-color: var(--color-base);
    --hero-block-bg-color: #fff;
    --hero-block-text-align: center;

    padding: var(--hero-block-title-horizontal-padding) var(--hero-block-title-vertical-padding) ;
    border-radius: var(--hero-block-border-radius);
    border: 1px solid var(--hero-block-border-color);
    background-color: var(--hero-block-bg-color);
    text-align: var(--hero-block-text-align);
    // @TODO - make it adjustable
    align-self: self-end;

    .h1 {
        --font-size: 32px;
        --line-height: 32px;
        // typography
        // 4% 56px
        letter-spacing: -2.24px;

        @include apply-screen-size(tablet-md) {
            --font-size: 56px;
            --line-height: 56px;
        }
    }

    @include apply-screen-size(tablet-md) {
        --hero-block-title-vertical-padding: 32px;
        --hero-block-title-horizontal-padding: 40px;
        --hero-block-border-radius: 16px;
        --hero-block-text-align: left;
    }
}

.form__content {
    display: block;

    .form.is-loading & {
        opacity: 0.4;
        pointer-events: none;
        transition: opacity 400ms;
    }
}

.form__step {
    display: block;
}

.form__content.is-hidden {
    display: none;
}

.form__row {
    display: block;

    // depends on MQ tablet-sm
    @media (min-width: 770px) {
        display: flex;
        align-items: stretch;
    }
}

.form__row + .form__row {
    margin-top: 16px;
}

.form__row + .form__row--gap {
    margin-top: 24px;
}

.form__col {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 0 auto;
    width: 100%;

    & + & {
        margin-top: 16px;
    }

    .form--third & {
        margin-top: 0;
        width: 33.33%;

        &.form__col--align-bottom {
            @media (min-width: 768px) {
                align-items: flex-end;
            }
        }
    }

    .form--third & + & {
        margin-left: 16px;
    }
}

.form__field {
    display: block;

    &.form__field--stretch {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1 1 auto;
    }

    &.form__field--inline {
        position: relative;
        display: flex;
        align-items: flex-start;
        max-width: 100%;
    }

    &.is-hidden {
        display: none;
    }
}

.form__description {
    display: block;
    margin: 0;
}

.form__title {
    display: block;
    margin: 0 0 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    font-family: $font-family-custom;

    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 1.125;
    }
}

.form__actions {
    display: block;
}

.form__footer {
    display: block;
    font-size: 12px;
    line-height: 16px;
}

.form__content-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
}

.form__control {
    margin: 0 auto;
}

.form__label {
    flex: 0 0 auto;
    width: 40%;
}

// Modificators
//
.form-wrapper--wide {
    max-width: 680px;
}

.form__col--half {
    & + & {
        @media (min-width: 768px) {
            margin-top: 0;
            margin-left: 32px;
        }
    }

    @media (min-width: 768px) {
        width: calc(50% - 16px);
        margin-top: 0;
    }
}

.form__col--align-bottom {
    justify-content: flex-end;
}

.form__actions--centered {
    text-align: center;
}

.form__footer--centered {
    text-align: center;
}

// form-email-capture
.form__email-capture-title {
    @include apply-screen-size(tablet-lg) {
        --font-size: 32px;
        --line-height: 35.2px;
        letter-spacing: -0.04em;
    }
}

.form__email-capture-subtitle {
    --font-size: 16px;
    --line-height: 19.2px;
    letter-spacing: -0.02em;
}

.form__email-capture-legal-caption {
    --font-size: 12px;
    --line-height: 14.4px;
    letter-spacing: -0.013em;

    @include apply-screen-size(tablet-md) {
        --font-size: 14px;
        --line-height: 16px;
        letter-spacing: -0.0175em;
    }
}

// States
//
.form__actions {
    &.is-hidden {
        display: none;
    }
}

.form__content-inner {
    &.is-hidden {
        display: none;
    }
}

// Relationships
//
.form__content-title + .form__content-inner {
    margin-top: 10px;
}

.form__content-inner + .form__content-buttons {
    margin-top: 32px;
}

.form__content-inner.is-hidden + .form__content-buttons {
    margin-top: 0;
}

.form__title + .form__description {
    margin-top: 16px;
}

.form__title + .form__content {
    margin-top: 32px;
}

.form__content + .form__content {
    margin-top: 32px;
}

.form__description + .form__content {
    margin-top: 16px;
}

.form__content + .form__actions {
    margin-top: 32px;
}

.form__content + .form__footer {
    margin-top: 16px;
}

.form__actions + .form__footer {
    margin-top: 16px;
}

.form__field + .form__field {
    margin-top: 16px;
}

// Get mobile app form
//
.form--get-app {
    .input-phone__field {
        border-color: $color-mode-dating;
    }
}

//
.form-field__input input {
    width: 100%;
    height: 44px;
    background: #fffcf3;
    box-shadow: 0 4px 0 #F2B304;
    border-radius: 8px;
    padding: 8px 12px;
}
