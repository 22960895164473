//
// Header
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

:root {
    --header-block-height: $header-height-mobile;

    @media (min-width: 600px) {
        --header-block-height: $header-height-tablet;
    }
    @media (min-width: 1200px) {
        --header-block-height: $header-height-desktop;
    }
}

.header {
    height: var(--header-block-height);
}

.header__inner {
    display: flex;
    justify-content: space-between;
    height: 100%;
    background: rgba(255, 255, 255, 0.97);

    @media screen and (min-width: 1200px) {
        height: var(--header-block-height);
    }
}

.header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    color: $color-mode-dating;

    @media screen and (max-width: 770px) {
        max-width: 140px;
    }
}

.header__language-selector {
    align-self: center;
    margin-right: auto;
    padding-top: 15px; // visual alignment

    @media screen and (max-width: 770px) {
        margin-left: 10px;
        padding-top: 7px; // visual alignment
    }
}

.header__menu {
    padding-right: 16px;
    padding-left: 16px;

    @media screen and (min-width: $media-min-mobile-menu-switch-off) {
        padding-right: 0;
    }
}
