//
// Header menu
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

@import '../__mixins/a11y-focus-ring.scss';

// Elements
//
.header-menu {
    display: flex;
    align-items: center;
    height: 100%;
}

.header-menu__item {
    display: none;
    height: inherit;
    transition: .2s color $bezier-timing-function, .2s background-color $bezier-timing-function;
    cursor: pointer;

    &:hover {
        color: $color-mode-dating;
    }

    &.is-disabled {
        cursor: default;

        &:hover {
            color: inherit;
        }
    }

    @media screen and (min-width: $media-min-mobile-menu-switch-off) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.header-menu__item--button {
    background-color: $color-mode-dating;
    color: #fff;

    .theme-bff &:not(:hover) {
        background-color: $color-mode-bff;
    }

    .theme-bizz &:not(:hover) {
        background-color: $color-mode-bizz;
    }

    &:hover {
        background: $color-base;
        color: #fff;
    }
}

.header-menu__item--dropdown {
    @media screen and (min-width: $media-min-mobile-menu-switch-off) {
        position: relative;

        &:focus-within,
        &:hover,
        &.is-active {
            .header-menu__dropdown {
                top: 100%;
                display: block;
            }
        }
    }
}

.header-menu__mobile-switcher {
    // @TODO: reveal menu later
    display: none;
    // display: block;
    padding: 16px 0;
    cursor: pointer;

    @media screen and (min-width: $media-min-mobile-menu-switch-off) {
        display: none;
    }
}

.header-menu__link {
    @include a11y-focus-ring('inside');

    display: flex;
    align-items: center;
    height: inherit;

    .header-menu__item.is-active & {
        cursor: default;
    }

    .header-menu__item.is-disabled & {
        pointer-events: none;
    }
}

.header-menu__link-text {
    padding: 22px 13px;
    color: inherit;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.333;
    font-family: $font-family-custom;
    pointer-events: none;
}

.header-menu__dropdown {
    z-index: -1;
    top: -300%;
    display: none;
    transition: .2s top $bezier-timing-function;

    @media screen and (min-width: $media-min-mobile-menu-switch-off) {
        position: absolute;
        left: 0;
        display: block;
        min-width: 100%;
        background: #fff;
    }
}

// States
//
.header-menu__item {
    &.is-active {
        color: $color-mode-dating;
    }
}
