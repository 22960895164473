@import 'typography.reference.scss';

.is-responsive {
    // safari clamp bug
    // https://stackoverflow.com/questions/37070694/font-size-calc-dynamic-size-safari-failing
    // still works not all the time and need investigation
    min-height: 0vw;
}

.title {
    @include apply-text-inherited();
    @include apply-text-pallete();

    --font-size: 9.53vw;
    --line-height: 1.1;

    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: var(--font-weight-black, 900);

    @include apply-screen-size(desktop-md) {
        --font-size: 11.50vw;
        line-height: 0.94;
    }
    @media screen and (min-width: 1280px) {
        --font-size: 10.50vw;
        line-height: 0.92;
    }
    @include apply-screen-size(desktop-xlg) {
        --font-size: 137.65px;
        --line-height: 0.95;
    }
    @media screen and (min-width: 1440px) and (min-height: 700px)  {
        --font-size: 167.65px;
        --line-height: 0.95;
    }
}

.subtitle {
    @include apply-text-inherited();
    @include apply-text-pallete();

    --font-size: 6.64vw;
    --line-height: 1.1;

    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: var(--font-weight-book, 450);

    @include apply-screen-size(desktop-md) {
        --font-size: 3.14vw;
    }
}

.h1 {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-header1();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-header1(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-header1();

        &.is-responsive {
            @include apply-typography-header1(true);
        }
    }

    > a {
        color: inherit;

        &:hover {
            opacity: 0.85;
        }
    }
}

.h2 {
    @include apply-text-inherited();
    @include apply-text-pallete();

    --font-size: 5.8vw;
    --line-height: 1.1;

    font-size: var(--font-size);
    font-family: var(--font-family-black);
    line-height: var(--line-height);
    font-weight: var(--font-weight-black, 900);
    letter-spacing: -0.05em;

    @include apply-screen-size(desktop-sm) {
        --font-size: 2.71vw;
    }
    @include apply-screen-size(desktop-xlg) {
        --font-size: 2.827rem;
    }
}

.h3 {
    @include apply-text-inherited();
    @include apply-text-pallete();

    --font-size: 5.8vw;
    --line-height: 1.1;

    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: var(--font-weight-black, 900);

    @include apply-screen-size(desktop-sm) {
        --font-size: 2.71vw;
    }
    @include apply-screen-size(desktop-md) {
        --font-size: 2.22vw;
    }
    @include apply-screen-size(desktop-xlg) {
        --font-size: 31.99px;
    }
}

.h4 {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-header4();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-header4(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-header4();

        &.is-responsive {
            @include apply-typography-header4(true);
        }
    }
}

.p-1 {
    @include apply-text-inherited();
    @include apply-text-pallete();

    --font-size: 4.1vw;
    --line-height: 1.2;

    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: var(--font-weight-book, 400);

    @include apply-screen-size(desktop-sm) {
        --font-size: 1.91vw;
    }
    @include apply-screen-size(desktop-xlg) {
        --font-size: 22.62px;
    }
}

.p-2 {
    --font-size: 22.62px;
    --line-height: 1.2;

    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: var(--font-weight-black, 900);

}

.p-3 {
    --font-size: 16px;
    --line-height: 1.1;

    font-size: var(--font-size);
    line-height: var(--line-height);
    font-weight: var(--font-weight-book, 400);
}

.caption {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-caption();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-caption(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-caption();

        &.is-responsive {
            @include apply-typography-caption(true);
        }
    }
}

strong,
b {
    font-weight: 600;
    font-family: var(--font-family-bold);
}
