//
// Logo
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

// Elements
//
.logo {
    position: relative;
    display: block;
    width: 188px;
    height: 32px;
    color: inherit;

    .page--bumble-bff & {
        width: 100px;
    }

}

.logo > svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    fill: currentColor;
}

.logo--bff-mode {
    color: $color-base-bff-maya;
}
