.hero-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
    max-height: inherit;

    &::before {
        display: block;
        padding-bottom: calc(100% / var(--aspect-ratio));
        content: '';
    }
}

.hero-image__media {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    // need it only for sandbox where CDNImage doesn't work
    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.hero-image__overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.hero-image--aspect-ratio {
    min-height: 0;
    aspect-ratio: var(--aspect-ratio);
}
