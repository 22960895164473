.benefit-section {
    display: block;

    // todo: page-section-inner should get
    // proper vars from page-section--padded
    .page__section-inner {
        --horizontal-gap: 50px;

        @include apply-screen-size(desktop-sm) {
            --horizontal-gap-large: 120px;
        }
    }

    // todo: proper reset for ul
    .grid {
        padding: 0;
    }
}

.benefit-section__header {
    @include apply-screen-size(desktop-sm) {
        text-align: left;
    }

    text-align: center;
}

.benefit-section__grid {
    --horizontal-gap: 20px;
}

.benefit-section__entry-title {
    font-weight: var(--font-weight-bold);
}

.benefit-section__entry-text {
    ul {
        list-style: disc;
        list-style-position: inside;
        text-align: left;
    }
}

.benefit-section__header + .grid {
    margin-top: 65px;
}

.benefit-section__entry-image {
    overflow: hidden;
    flex: 0 0 auto;

    .media-box__picture {
        background-color: transparent;
    }
}

.benefit-section {
    &:not(.page__section--secondary) {
        .benefit-section__entry-image {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background: var(--color-primary);
        }
    }

    &.page__section--secondary {
        .benefit-section__entry-image {
            @include apply-screen-size(desktop-sm) {
                width: 150px;
                height: 150px;
            }

            width: 120px;
            height: 120px;
            margin-left: -30px;
        }

        .benefit-section__entry-text {
            margin-top: 10px;
        }

        .cta-box:first-child {
            margin-top: -30px;
        }
    }

    &.benefit-section--blog {
        .benefit-section__entry-image {
            width: 100%;

            &::before {
                content: '';
                width: 100%;
                display: block;
                padding-bottom: calc(1 / var(--ratio) * 100%);
            }
        }
    }
}
