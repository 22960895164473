.trend-of-the-year {
    display: flex;
    justify-content: center;
    width: 100%;
}

.trend-of-the-year__link {
    position: relative;
    max-width: 160px;
    width: 100%;

    &::before {
        content: '';
        display: block;
        padding-top: 100%;
        width: 100%;
    }
}

.trend-of-the-year__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}
