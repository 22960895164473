//
// footer-menu
//
// @maintainer: Konstantin Dzuin (k.dzuin@corp.badoo.com)
// @maintainer: Ivan Grekov (ivan.grekov@corp.badoo.com)
//

.footer-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-menu__item {
    position: relative;
    padding: 0 9px 0 8px;
    font-weight: 300;

    &::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 12px;
        content: '';
        border-right: 1px solid;
        transform: translate3d(0, -50%, 0);
    }

    &:last-child {
        &::after {
            content: none;
        }
    }

    &.is-hidden {
        display: none;
        visibility: hidden;
    }
}

.footer-menu__link {
    color: #333;
    white-space: nowrap;
    transition: .2s color $bezier-timing-function;

    &:hover {
        color: $color-accent;
    }

    &[role="link"] {
      cursor: pointer;
    }

    .is-active & {
        color: $color-accent;
        cursor: default;
    }

    &.is-hidden {
        display: none;
        visibility: hidden;
    }
}
