//
// Instagram feed
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

.instagram-feed__title {
    text-align: center;
}

.instagram-feed__list {
    display: flex;
    flex-wrap: wrap;
}

.instagram-feed__item {
    flex: 0 0 auto;
    width: calc(50% - 8px);

    &:nth-child(n) {
        margin-left: 16px;
    }

    &:nth-child(2n+1) {
        margin-left: 0;
    }

    &:nth-child(n+3) {
        margin-top: 16px;
    }

    @media screen and (min-width: 768px) {
        width: calc(33.333% - 12px);

        &:nth-child(n) {
            margin-top: 0;
            margin-left: 16px;
        }

        &:nth-child(3n+1) {
            margin-left: 0;
        }

        &:nth-child(n+4) {
            margin-top: 16px;
        }
    }
    @media screen and (min-width: 1200px) {
        width: calc(16.666% - 14px);

        &:nth-child(n) {
            margin-top: 0;
            margin-left: 16px;
        }

        &:nth-child(6n+1) {
            margin-left: 0;
        }

        &:nth-child(n+7) {
            margin-top: 16px;
        }
    }
}

.instagram-feed__item-image {
    width: 100%;
}

// Relationships
//
.instagram-feed__title + .instagram-feed__list {
    margin-top: 16px;

    @media screen and (min-width: 770px) {
        margin-top: 32px;
    }
}
