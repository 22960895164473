//
// SCSS mixin for adding keyboard focus ring
// https://www.sarasoueidan.com/blog/focus-indicators/
//
// Examples of usage:
//
//.element-with-outer-focus-ring {
//    @include a11y-focus-ring();
//}
//
//.element-with-inner-focus-ring {
//    @include a11y-focus-ring(inside);
//}
//
//.element-with-custom-focus-ring {
//    @include a11y-focus-ring() {
//        outline-color: green;
//    };
//}
//
//
//<label> <-- we want focus ring to be shown for LABEL instead of INPUT
//    <input type="checkbox">
//</label>
//
//.label {
//    @include a11y-parent-focus-ring();
//
//    display: block;
//}

$token-a11y-focus-ring-outer-width: 1px;
$token-a11y-focus-ring-outer-offset: 2px;
$token-a11y-focus-ring-inner-width: 2px;
$token-a11y-focus-ring-inner-offset: -3px;
$token-color-primary-dark: #de9e24; // from Cosmos


@mixin a11y-focus-styles($focus-ring-position: 'outside') {
    @if ($focus-ring-position == 'outside') {
        outline: $token-a11y-focus-ring-outer-width solid var(--focus-ring-outer-color, #{$token-color-primary-dark});
        outline-offset: $token-a11y-focus-ring-outer-offset;
    }

    @else {
        outline: $token-a11y-focus-ring-inner-width solid var(--focus-ring-inner-color, #{$token-color-primary-dark});
        outline-offset: $token-a11y-focus-ring-inner-offset;
    }

    // for cases when we need to slightly override focus ring behaviour
    @content;
}
@mixin a11y-focus-ring($focus-ring-position: 'outside') {
    &:focus,
    .trigger-child-focus-ring:focus &, // focusing on  a parent will cause showing focus ring in a child
    .a11y-show-focus-ring & { // for debug and VRT purposes
        @include a11y-focus-styles($focus-ring-position) {
            @content;
        }
    }

    &:focus:not(:focus-visible) { /* stylelint-disable-line a11y/no-outline-none */
        outline: none;
    }
}
@mixin a11y-parent-focus-ring($focus-ring-position: 'outside') {
    &:focus-within,
    .a11y-show-focus-ring & { // for debug and VRT purposes
        @include a11y-focus-styles($focus-ring-position) {
            @content;
        }

        * { /* stylelint-disable-line a11y/no-outline-none */
            outline: none;
        }
    }

    &:has(:focus:not(:focus-visible)) { /* stylelint-disable-line a11y/no-outline-none */
        outline: none !important;
    }
}
