$font-family-custom: var(--font-family-book);

@import 'foundation/reference/colors.scss';
@import 'foundation/reference/transitions.scss';
// @import 'foundation/reference/typography.scss';
@import 'foundation/reference/media-queries.scss';
@import 'foundation/reference/layout.scss';
@import 'components/reference/header.scss';
// @import 'foundation/reference/typography.scss';
@import 'foundation/layout.scss';
@import 'foundation/links.scss';
// @import 'foundation/typography.scss';
@import 'components/header-bar.scss';
@import 'components/header.scss';
@import 'components/header-menu.scss';
@import 'components/header-dropdown.scss';
@import 'components/instagram-feed.scss';
@import 'components/language-selector.scss';
@import 'components/dropdown-list.scss';
@import 'components/navigation-switcher.scss';
@import 'components/footer.scss';
@import 'components/footer-menu.scss';
@import 'components/logo.scss';
@import 'components/trend-of-the-year.scss';
@import 'components/slider-svg.scss';
@import 'foundation/form.scss';
@import 'components/forms/form-field.scss';
@import 'components/forms/captcha.scss';
