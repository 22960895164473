//
// Header
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

$header-height-mobile: 64px;
$header-height-tablet: 64px;
$header-height-desktop: 64px;

// Z-indexes
$z-header-navigation: 100;
$z-header-menu-dropdown: 101;
