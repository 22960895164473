html,
body,
main {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
}

:root {
    --color-white: #fff;
    --color-smoke: #d8d8d8;
    --color-gray: #9b9b9b;
    --color-grey: #a7a7a7;
    --color-boulder: #5a5a5a;
    --color-black: #444647;
    --color-yellow: #ffc629;
    --color-lemon: #fdda64;
    --color-pollen: #fde780;
    --color-banana: #f3efa1;
    --color-cream: #fffaeb;
    --color-salmon: #f47c51;
    --color-navy: #064469;
}

.boulder {
    color: var(--color-boulder);
}

.black {
    color: var(--color-black);
}

.gray {
    color: var(--color-gray);
}

.smoke {
    color: var(--color-smoke);
}

.navy {
    color: var(--color-navy);
}

.yellow {
    color: var(--color-yellow);
}

.banana {
    color: var(--color-banana);
}

.lemon {
    color: var(--color-lemon);
}

.pollen {
    color: var(--color-pollen);
}

.salmon {
    color: var(--color-salmon);
}

.cream {
    color: var(--color-cream);
}

.white {
    color: var(--color-white);
}

.grey {
    color: var(--color-grey);
}

.boulder-bg {
    background-color: var(--color-boulder);
}

.black-bg {
    background-color: var(--color-black);
}

.gray-bg {
    background-color: var(--color-gray);
}

.smoke-bg {
    background-color: var(--color-smoke);
}

.navy-bg {
    background-color: var(--color-navy);
}

.yellow-bg {
    background-color: var(--color-yellow);
}

.banana-bg {
    background-color: var(--color-banana);
}

.lemon-bg {
    background-color: var(--color-lemon);
}

.pollen-bg {
    background-color: var(--color-pollen);
}

.salmon-bg {
    background-color: var(--color-salmon);
}

.cream-bg {
    background-color: var(--color-cream);
}

.white-bg {
    background-color: var(--color-white);
}

.white-border {
    border-color: var(--color-white);
}

.yellow-border {
    border-color: var(--color-yellow);
}

.black-border {
    border-color: var(--color-black);
}

.boulder-border {
    border-color: var(--color-boulder);
}

.flex {
    display: flex;
}

.flex.space-between {
    justify-content: space-between;
}

.flex.h-center {
    justify-content: center;
}

.flex.h-start {
    justify-content: flex-start;
}

.flex.h-end {
    justify-content: flex-end;
}

.flex.v-center {
    align-items: center;
}

.flex.v-start {
    align-items: flex-start;
}

.flex.v-end {
    align-items: flex-end;
}

.flex.v-baseline {
    align-items: baseline;
}

.flex.col,
.flex.column {
    flex-direction: column;
}

.flex.row {
    flex-direction: row;
}

.flex.row-reverse {
    flex-direction: row-reverse;
}

.flex.align-start {
    align-items: flex-start;
}

.flex.wrap {
    flex-wrap: wrap;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.inline-block.h-center {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 900px) {
    .lg\:break {
        display: block;
    }

    .lg\:hide {
        display: none;
    }
}

.w-1-1 {
    width: 100%;
}

.w-3-5 {
    width: 60%;
}

.w-1-2 {
    width: 50%;
}

.w-2-5 {
    width: 40%;
}

.w-1-3 {
    width: 33.3333%;
}

@media only screen and (max-width: 900px) {
    .md\:w-1-1 {
        width: 100%;
    }

    .md\:w-3-5 {
        width: 60%;
    }

    .md\:w-1-2 {
        width: 50%;
    }

    .md\:w-2-5 {
        width: 40%;
    }

    .md\:w-1-3 {
        width: 33.3333%;
    }

    .block.md\:h-center > * {
        margin-left: auto;
        margin-right: auto;
    }

    .md\:flex {
        display: flex;
    }

    .md\:flex.md\:h-center,
    .flex.md\:h-center {
        justify-content: center;
    }

    .md\:flex.md\:col,
    .md\:flex.md\:column,
    .flex.md\:col,
    .flex.md\:column {
        flex-direction: column;
    }

    .md\:hide {
        display: none !important;
    }

    .md\:no-padding {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
    .md\:break {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .sm\:w-1-1 {
        width: 100%;
    }

    .sm\:w-3-5 {
        width: 60%;
    }

    .sm\:w-1-2 {
        width: 50%;
    }

    .sm\:w-2-5 {
        width: 40%;
    }

    .sm\:w-1-3 {
        width: 33.3333%;
    }

    .sm\:break {
        display: block;
    }

    .sm\:hide {
        display: none !important;
    }
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.center-align {
    text-align: center;
}

.nowrap {
    white-space: nowrap;
}

.underline {
    text-decoration: underline;
}

.uppercase {
    text-transform: uppercase;
}

.weight-book {
    font-family: var(--font-family-book) !important;
}

.weight-book-italic {
    font-family: var(--font-family-book) Im !important;
    font-style: italic;
}

.weight-medium,
.weight-semi-bold {
    font-family: var(--font-family-medium) !important;
}

.weight-medium-italic,
.weight-semi-bold-italic {
    font-family: var(--font-family-medium) !important;
    font-style: italic;
}

.weight-bold {
    font-family: var(--font-family-bold) !important;
}

.weight-bold-italic {
    font-family: var(--font-family-bold) !important;
    font-style: italic;
}

.weight-black {
    font-family: var(--font-family-black) !important;
}

.weight-black-italic {
    font-family: var(--font-family-black) !important;
    font-style: italic;
}

@media only screen and (max-width: 900px) {
    .md\:left-align {
        text-align: left;
    }

    .md\:right-align {
        text-align: right;
    }

    .md\:center-align {
        text-align: center;
    }

    .md\:nowrap {
        white-space: nowrap;
    }
}

h1,
h1 span {
    font-weight: 400;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -0.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-family: var(--font-family-black);
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    -webkit-font-smoothing: antialiased;
}

h2 {
    font-weight: 400;
    font-size: 48px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    margin-top: 4px;
    margin-bottom: 4px;
    text-transform: none;
    font-family: var(--font-family-bold);
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    -webkit-font-smoothing: antialiased;
}

h3,
h3 span {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 0em;
    text-transform: none;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: var(--font-family-book);
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    -webkit-font-smoothing: antialiased;
}

p {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 1.4;
    margin-top: 2px;
    margin-bottom: 2px;
    font-family: var(--font-family-book);
    -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 900px) {
    h1,
    h1 span,
    h2,
    h2 span {
        font-size: 40px;
        line-height: 1.05;
        letter-spacing: 0em;
        margin-top: 4px;
        margin-bottom: 4px;
        font-family: var(--font-family-book);
    }

    h3,
    h3 span {
        font-size: 25px;
        line-height: 1.2;
        letter-spacing: 0em;
    }

    p {
        font-size: 18px;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        h1,
        h1 span {
            letter-spacing: -0.035em !important;
        }

        h2,
        h2 span {
            letter-spacing: -0.02em !important;
        }

        h3,
        h3 span {
            letter-spacing: -0.015em !important;
        }
    }
}

@media only screen and (max-width: 900px) {
    h2,
    h2 span,
    h3,
    h3 span,
    h2.weight-medium,
    h2.weight-semi-bold,
    h3.weight-medium,
    h3.weight-semi-bold,
    h2.weight-medium span,
    h2.weight-semi-bold span,
    h3.weight-medium span,
    h3.weight-semi-bold span {
        font-family: var(--font-family-bold) !important;
    }
}

#navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
}

.sqs-block-editable #navbar {
    top: 72px;
}

#navbar .mobile {
    display: none;
}

#navbar > div {
    padding: 21px 60px;
}

.navbar-logo {
    width: 160px;
    display: flex;
}

.navbar-logo img {
    width: 100%;
}

#navbar a:not(.navbar-logo) {
    text-decoration: none;
    font-size: 20px;
    line-height: 24px;
}

#navbar a:not(.navbar-logo) {
    border-bottom: 2px solid transparent;
}

#navbar a:not(.navbar-logo):hover {
    border-bottom: 2px solid var(--color-black);
}

#navbar-window .download,
#navbar .download {
    padding: 6px 20px;
    border: 1px solid var(--color-black) !important;
    border-radius: 40px;
}

#navbar-window .download:hover,
#navbar .download:hover {
    background-color: var(--color-black);
    color: var(--color-yellow);
    text-decoration: none;
}

#navbar .desktop.links {
    display: flex;
    gap: 36px;
    align-items: center;
}

@media only screen and (max-width: 900px) {
    #navbar .desktop {
        display: none !important;
    }

    #navbar .mobile {
        display: block;
    }

    #navbar > div {
        padding: 0px 20px;
    }

    .navbar-logo {
        display: flex;
        width: 98px;
        height: 20px;
    }

    #navbar .hamburger {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        height: 48px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    #navbar .hamburger img {
        width: 22px;
    }
}

#footer {
    padding: 7vw 10vw;
    box-sizing: border-box;
}

#footer .content {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

#footer .logo {
    display: block;
    width: 12em;
    margin-bottom: 22px;
}

#footer .download {
    display: block;
    text-decoration: none;
    border-radius: 50px;
    border: 2px solid var(--color-yellow);
    padding: 6px 24px;
    text-transform: uppercase;
}

#footer .download:hover {
    background-color: var(--color-yellow);
    color: var(--color-black);
}

#footer .trend-award {
    height: 125px;
    width: 125px;
}

#footer .links {
    margin-top: 100px;
    margin-bottom: 150px;
    gap: 80px;
}

#footer .links .section {
    border-top: 1px solid var(--color-yellow);
    padding-top: 8px;
    margin-bottom: 20px;
    font-size: 16px;
}

#footer .links a {
    text-decoration: none;
    display: table;
    margin-bottom: 6px;
    font-size: 16px;
}

#footer .links a:hover {
    text-decoration: underline;
}

#footer .make-the-first-move {
    margin-bottom: -5px;
}

#footer .copyright {
    font-size: 12px;
    padding-top: 4px;
}

#footer .social-icons {
    display: flex;
    gap: 40px;
}

#footer.mobile .social-icons {
    margin-bottom: 28px;
}

#footer .social-icons {
    a {
        display: flex;
    }

    use {
        fill: #444647;
    }

    svg,
    img {
        width: 34px;
        height: 34px;

        &:last-child {
            display: none;
        }
    }

    a:hover {
        svg,
        img {
            &:first-child {
                display: none;
            }
            &:last-child {
                display: block;
            }
        }
    }
}

@media only screen and (min-width: 900px) {
    #footer .content > .flex:first-child > div:first-child {
        margin-bottom: -12px;
    }
}

@media only screen and (max-width: 900px) {
    #footer {
        padding: 50px 10vw;
    }

    #footer .links {
        flex-direction: column;
        gap: 16px;
        margin-top: 60px;
        margin-bottom: 40px;
    }

    #footer .links .section {
        margin-bottom: 8px;
        font-size: 18px;
        padding-top: 12px;
        font-weight: 400;
        font-family: var(--font-family-medium) !important;
    }

    #footer .links a {
        font-size: 18px;
    }

    #footer .trend-award {
        margin-bottom: 36px;
    }

    #footer .social-icons {
        margin-top: 24px;
        gap: 10px;

        //
        
    }

    #footer.mobile-panel__socials  .social-icons {
        width: 100%;
        justify-content: space-between;
    }

    #footer .social-icons {
        img, svg {
            width: 36px;
        }
    }
}

@media only screen and (max-width: 600px) {
    #footer {
        padding: 50px 24px;
    }
}
