.grid {
    display: flex;
    flex-wrap: wrap;
}

.grid__cell {
    flex: 0 0 auto;
    width: calc(50% - var(--horizontal-gap) / 2);
    margin-left: var(--horizontal-gap);

    .grid--triple--min-tablet & {
        width: 100%;
        margin-left: 0;
    }
}

.grid__cell:nth-child(2n + 1) {
    margin-left: 0;
}

.grid__cell:nth-child(n + 3) {
    margin-top: var(--vertical-gap);

    .grid--triple--min-tablet & {
        margin-top: calc(var(--vertical-gap) * 2);
    }
}

.grid__cell + .grid__cell {
    .grid--triple--min-tablet & {
        margin-top: calc(var(--vertical-gap) * 2);
    }
}

@media screen and (min-width: $media-tablet) {
    .grid--reverse {
        .grid__cell {
            width: 100%;
            margin-left: 0;
        }

        .grid__cell + .grid__cell {
            margin-top: 32px;
        }
    }

    .grid--double {
        .grid__cell {
            width: calc(50% - var(--horizontal-gap-lg) / 2);
            margin-left: var(--horizontal-gap-lg);
        }

        .grid__cell:nth-child(2n + 1) {
            margin-left: 0;
        }
    }

    .grid--triple--min-tablet .grid__cell + .grid__cell {
        margin-top: 0;
    }
}
@media screen and (min-width: $media-tablet) {
    .grid--triple {
        .grid__cell {
            width: calc(33.333% - var(--horizontal-gap-large) * 2 / 3);
            margin-left: var(--horizontal-gap-large);
        }

        .grid__cell:nth-child(2n + 1) {
            margin-left: var(--horizontal-gap-large);
        }

        .grid__cell:nth-child(3n + 1) {
            margin-left: 0;
        }

        .grid__cell:nth-child(n + 3) {
            margin-top: 0;
        }

        .grid__cell:nth-child(n + 4) {
            margin-top: var(--vertical-gap-large);
        }
    }
}

.grid--triple-mini-gap {
    --vertical-gap: 24px;

    .grid__cell {
        width: 100%;
        margin-left: 0;
    }

    .grid__cell + .grid__cell {
        margin-top: var(--vertical-gap);
    }

    @media screen and (min-width: $media-tablet) {
        .grid__cell {
            display: flex;
            flex-direction: column;
            flex: 0 0 auto;
            width: calc(50% - var(--horizontal-gap) / 2);
            margin-left: var(--horizontal-gap);
        
            .grid--triple--min-tablet & {
                width: 100%;
                margin-left: 0;
            }
        }

        .grid__cell + .grid__cell {
            margin-top: 0;
        }

        .grid__cell:nth-child(n + 3) {
            margin-left: var(--horizontal-gap);
            margin-top: var(--vertical-gap);
        }

        .grid__cell:nth-child(2n + 1) {
            margin-left: 0;
        }
    }

    @media screen and (min-width: $media-desktop) {
        margin-bottom: calc(-1 * var(--vertical-gap-large));

        .grid__cell {
            width: calc(33.333% - var(--horizontal-gap-large) * 2 / 3);
            padding-bottom: var(--vertical-gap-large);
        }

        .grid__cell + .grid__cell {
            margin-left: var(--horizontal-gap-large);
        }

        .grid__cell:nth-child(n + 3) {
            margin-top: 0;
        }

        .grid__cell:nth-child(3n + 1) {
            margin-left: 0;
        }
    }
}