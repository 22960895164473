//
// Dropdown list
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

$dropdown-list-top-gap: 10px;

// Elements
//
.dropdown-list {
    position: absolute;
    z-index: 90;
    top: 100%;
    left: 0;
    margin-top: $dropdown-list-top-gap;
    color: $color-base;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s opacity $bezier-timing-function;

    &::before {
        display: block;
        width: 100%;
        height: $dropdown-list-top-gap;
        margin-top: -$dropdown-list-top-gap;
        content: '';
    }

    .trigger-dropdown-list.is-active & {
        opacity: 1;
        visibility: visible;
    }

    .language-selector__inner:hover & {
        opacity: 1;
        visibility: visible;
    }

    .language-selector__trigger:checked + .language-selector__dropdown & {
        opacity: 1;
        visibility: visible;
    }
}

.dropdown-list--top {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-list-top-gap;

    &::before {
        display: none;
    }

    &::after {
        display: block;
        width: 100%;
        height: $dropdown-list-top-gap;
        margin-bottom: -$dropdown-list-top-gap;
        content: '';
    }
}

// todo: fix after release
.header__language-selector .dropdown-list,
.dropdown-list--center {
    right: auto;
    left: calc(100% - 6px);
    transform: translate3d(-50%, 0, 0);
}

.dropdown-list--fullwidth {
    min-width: 100%;
}

.dropdown-list__inner {
    overflow-y: scroll;
    max-height: 280px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 2px solid $color-gray-light;
    background: #fff;

    &::before {
        display: block;
        content: '';
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        z-index: 1;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent $color-gray-light transparent;
    }

    &::after {
        display: block;
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        z-index: 2;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        border-color: transparent transparent #fff transparent;
    }
}

.dropdown-list__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    transition: 0.2s color $bezier-timing-function;
}

.dropdown-list__item-title {
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.2;
    pointer-events: none;
}

.dropdown-list__item-label {
    font-size: 14px;
    line-height: 1.2;
    pointer-events: none;
}

.trigger-dropdown-list {
    position: relative;
    cursor: pointer;
}

// States
//
.dropdown-list__item {
    &:hover,
    &.is-active {
        color: $color-mode-dating;
    }

    &.is-active {
        cursor: default;
    }
}

// Relationships
//
.dropdown-list__item-title + .dropdown-list__item-label {
    margin-left: 16px;
}
