.captcha {
    width: 100%;
}

.captcha__media {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--input-height-base);
    background-color: #FFFCF3;
    border-radius: 8px;
}

.captcha__code {
    padding-top: 4px;
    display: flex;
}

.captcha__reset {
    position: absolute;
    top: 50%;
    right: 8px;
    color: #e7e7e7;
    transform: translate3d(0, -50%, 0);
    cursor: pointer;
}

.captcha__reset-icon {
    pointer-events: none;
}

.captcha__media + .captcha__field {
    margin-top: 8px;
}
