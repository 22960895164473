//
// Links with hover
//
// @maintainer: Konstantin Dzuin (k.dzuin@corp.badoo.com)
// @maintainer: Ivan Grekov (ivan.grekov@corp.badoo.com)
//

a {
    color: currentColor;
    text-decoration: none;
    cursor: pointer;
}

.link {
    color: $color-link;
    transition: color .2s;
    cursor: pointer;
}
