//
// Navigation switcher
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

// Elemetns
//
.navigation-switcher {
    position: relative;
    display: block;
    width: 24px;
    height: auto;
    margin: auto;
    fill: currentColor;
    pointer-events: none;
}

.navigation-switcher__bar {
    width: 24px;
    height: 2px;
    background: #444647;

    .mobile-navigation__switcher & {
        &:nth-child(1) {
            transform: translate3d(0, 8px, 0) rotate(45deg);
        }

        &:nth-child(2) {
            opacity: 0;
        }

        &:nth-child(3) {
            transform: translate3d(0, -8px, 0) rotate(-45deg);
        }
    }
}

// Relationships
//
.navigation-switcher__bar + .navigation-switcher__bar {
    margin-top: 6px;
}
