//
// Header bar
//
// @maintainer: Ilya Katasonov (ilya.katasonov@corp.badoo.com)
//

// Elements
//
.header-bar {
    display: block;
    width: 100%;
    margin: 0 auto;
}

.header-bar__logotype {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    color: $color-mode-dating;

    @media screen and (min-width: 768px) {
        height: 126px;
    }
}

.header-bar__navigation {
    height: $header-height-mobile;

    @media (min-width: 600px) {
        height: $header-height-tablet;
    }
    @media (min-width: 1200px) {
        height: $header-height-desktop;
    }
}

.header-bar__navigation-inner {
    z-index: $z-header-navigation;
    display: block;
    transition: .2s transform;

    &.is-fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }

    &.is-hidden {
        transform: translate3d(0, -100%, 0);
    }

    @media screen and (min-width: 600px) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;

        &.is-new {
            max-width: 100%;

            .header__inner {
                position: relative;
            }
    
            .header__menu {
                flex: 0;
            }
    
            .header__logo {
                max-width: 188px
            }
        }
    }
    @media screen and (min-width: 1200px) {
        max-width: $layout-max-width;
        width: 100%;
        margin: 0 auto;
    }
}

.header-bar__hero-block {
    display: block;
    max-width: $layout-max-width;
    margin: 0 auto;
}

.header-bar__hero-block--product {
    .hero-block__content {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .hero-block__title {
        margin-bottom: 12px;
    }

    .hero-block__title + .hero-block__text {
        display: block;
    }

    .input-phone__field {
        border-color: #E7E7E7;
    }

    @media screen and (min-width: 770px) {
        min-height: 460px;
    }

    @media screen and (min-width: 1200px) {
        min-height: 600px;
    }
}

.header-bar--bottom-gap {
    margin-bottom: 64px;
}

.header-bar--text-present {
    .hero-block__text.is-mobile-only {
        display: block;
        margin-top: 12px;

        @media screen and (min-width: 770px) {
            display: none;
            margin-top: 0;
        }
    }
}

.header-bar--service-unavailable {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    .header-bar__hero-block {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        @media (min-width: 770px) {
            display: block;
        }
    }

    .hero-block {
        flex: 1 0 auto;
    }

    @media (min-width: 770px) {
        display: block;
    }
}
