.icon {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    fill: currentColor;
    -webkit-font-smoothing: antialiased;
}

.icon svg {
    display: block;
    box-sizing: border-box;
    max-width: inherit;
    width: inherit;
    max-height: inherit;
    height: inherit;
    background: inherit;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-transform: translateX(0); // hack for Mozila: in 11 ver. svg elements seem to be drawn in a blurry way. This string clarifies images.
    pointer-events: none;

    use {
        transition: opacity 0.2s;
    }
}

.icon__content {
    width: inherit;
    height: inherit;
    fill: inherit;
    pointer-events: none;
}

.icon--sm {
    width: 16px;
    height: 16px;
}

.icon--xsm {
    width: 20px;
    height: 20px;
}

.icon--stretch {
    width: inherit;
    height: inherit;
}

.icon--inline {
    display: inline;

    svg {
        display: inline;
    }
}

.icon--primary {
    color: var(--color-primary);

    .badoo & {
        --color-primary: #783cfa;
    }
}

.icon--primary-black {
    color: rgb(29, 37, 44);
}
