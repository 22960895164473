$columns-gap: 64px;
$columns-gap-for-two: $columns-gap * 0.5;
$column-mq: tablet-md;

.columns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
}

.columns__item {
    // @todo: probably rethink later with concepts of listeners
    @include apply-sticky-effect();

    display: block;
    width: 100%;

    // TODO: link the value to the grid sizes
    // VERY BAD TEMP SOLUTION - styling inner blocks depending on layout
    &:only-child {
        max-width: 675px;
        margin: 0 auto;
        text-align: center;
    }

    // TODO: check - probably it's not CTA box here
    .cta-box {
        padding: 0;
    }
}

.columns__item + .columns__item {
    margin-left: var(--columns-item-gap, $columns-gap);
}

.columns__item--one-third {
    flex: 0 0 calc(33.3% - var(--columns-item-gap, #{$columns-gap-for-two}));
}

.columns__item--two-third {
    flex: 0 1 calc(66.6% - var(--columns-item-gap, #{$columns-gap-for-two}));
}

.columns__item--center {
    display: flex;
    justify-content: center;
    text-align: center;
}

.columns--align-top {
    align-items: flex-start;
}

.columns__item--within {
    overflow: hidden;
}

.columns__item--title {
    @include apply-screen-size(tablet-md) {
        align-self: center;
    }

    @include apply-screen-size(desktop-lg) {
        .columns--mobile-inverted &.columns__item {
            flex-basis: 50%;
        }
    }
}

.columns__item--slider {
    flex: 1 0 43%;

    .columns--mobile-inverted &.columns__item {
        width: 100%;
        margin: auto;

        @include apply-screen-size(tablet-md) {
            width: 80%;
        }
    }

    @include apply-screen-size(desktop-lg) {
        display: flex;

        .columns--mobile-inverted &.columns__item {
            flex-basis: 42%;
            margin-right: 0;
        }
    }
}

.columns__item--right-gap {
    @include apply-screen-size(desktop-sm) {
        padding-right: 5%;
    }
}

.columns__item--hero-image {
    .hero-image {
        @include apply-screen-size(desktop-md) {
            transform: scale(1.1);
            transform-origin: right;
            margin: 50px 0;
        }
    }
}

.column__item--tablet-cta {
    position: relative;

    .column__item-cta {
        display: flex;

        @include apply-screen-size(tablet-md) {
            position: absolute;
            bottom: 5%;
            right: 6%;
        }

        @include apply-screen-size(desktop-lg) {
            bottom: 5%;
        }
    }

    .column__item-title {
        @include apply-screen-size(desktop-lg) {
            --text-color: #fff;

            position: absolute;
            bottom: 5%;
            left: 6%;
            width: 55%;
        }
    }

    .column__item-cta-top {
        @include apply-screen-size(desktop-lg) {
            position: absolute;
            top: 4%;
            left: 6%;
            width: 70%;
        }
    }
}

.column__item-cta-bottom {
    position: absolute;
    bottom: 0;
    margin: 0px 12% 10% 8%;
    height: 22%;
    display: flex;
    align-items: flex-end;

    .h2 {
        padding-top: 2%;
        background-color: #ffde3b;

        @include apply-screen-size(desktop-sm) {
            --font-size: 5.8vw;

            padding-top: 3%;
        }
        @include apply-screen-size(desktop-xlg) {
            --font-size: 5.8vw;
        }
    }

    @include apply-screen-size(tablet-md) {
        display: none;
    }
}

.column__item--form-email-capture {
    padding-top: 2em;
}

.columns--align-bottom {
    align-items: flex-end;
}

.columns--mobile {
    @include apply-screen-size($column-mq) {
        flex-direction: row;
    }

    flex-direction: column;

    .columns__item + .columns__item {
        @include apply-screen-size($column-mq) {
            margin-top: 0;
            margin-left: var(--columns-item-gap, $columns-gap);
        }

        margin-top: var(--columns-item-gap, 40px);
        margin-left: 0;
    }
}

.columns--mobile-inverted {
    @include apply-screen-size($column-mq) {
        display: flex;
        flex-direction: row-reverse;
    }

    display: block;

    .columns__item {
        flex: 0 1 100%;
        width: 100%;
    }

    .columns__item + .columns__item {
        @include apply-screen-size($column-mq) {
            margin-top: 0;
            margin-right: var(--columns-item-gap, 64px);
        }

        margin-top: var(--columns-item-gap, 32px);
        margin-left: 0;
    }
}

.columns--equal {
    .columns__item {
        @include apply-screen-size($column-mq) {
            flex: 0 1 50%;
            width: 50%;
        }
    }
}
