.mobile-menu {
    display: block;
}

.mobile-menu__title {
    font-weight: var(--font-weight-bold);
    font-size: 40px;
    line-height: 40px;
    font-family: $font-family-custom-bold;

    .link {
        @include apply-brand-link-hover-state(4px, var(--color-primary));

        color: var(--menu-foreground);
        transition: color 400ms ease-in-out;

        &::after {
            opacity: 0;
        }

        &:hover {
            border-bottom-color: transparent;
            color: var(--color-primary);

            &::after {
                opacity: 1;
            }
        }
    }
}

.mobile-menu__list {
    display: block;
}

.mobile-menu__item {
    display: inline-block;
    width: 100%;
}

.mobile-menu__link {
    @include apply-link-block();
}

.mobile-menu__title + .mobile-menu__list {
    margin-top: 8px;
}

@media screen and (min-width: $media-desktop) {
    .mobile-menu__link,
    .mobile-menu__title-link {
        @include apply-link-block-hovered();
    }
}
