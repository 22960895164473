.social-links {
    --social-links-gap: 12px;
    --social-links-gap-large: 16px;

    display: block;
}

.social-links__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--social-links-gap) * -1);
    padding: 0;
}

.social-links__item {
    margin: var(--social-links-gap) var(--social-links-gap) 0 0;
}

.social-links__link {
    @include apply-link-block();

    color: var(--color-base);
}

.social-links__icon {
    cursor: pointer;
}

.social-links--shares {
    .social-links__item:last-child {
        margin-right: 0;
    }
}

@include apply-screen-size(desktop-sm) {
    .social-links__list {
        margin-top: calc(var(--social-links-gap-large) * -1);
    }

    .social-links__item {
        margin: var(--social-links-gap-large) var(--social-links-gap-large) 0 0;
    }
}

@media screen and (min-width: $media-desktop) {
    .social-links__link {
        @include apply-link-block-hovered();
    }
}

.social-links--bff {
    --social-links-gap: 8px;
    --social-links-gap-large: 8px;

    display: block;
}
